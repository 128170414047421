// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as TooltipScss from "./Tooltip.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = TooltipScss;

function toClassName(x) {
  switch (x) {
    case "Top" :
        return css.positionTop;
    case "Bottom" :
        return css.positionBottom;
    case "Left" :
        return css.positionLeft;
    case "Right" :
        return css.positionRight;
    
  }
}

var Position = {
  toClassName: toClassName
};

function Tooltip(props) {
  var children = props.children;
  var __withoutArrow = props.withoutArrow;
  var __tooltipContainerClass = props.tooltipContainerClass;
  var __className = props.className;
  var __hideWhen = props.hideWhen;
  var id = props.id;
  var hideWhen = __hideWhen !== undefined ? __hideWhen : false;
  var className = __className !== undefined ? __className : "";
  var tooltipContainerClass = __tooltipContainerClass !== undefined ? __tooltipContainerClass : "";
  var withoutArrow = __withoutArrow !== undefined ? __withoutArrow : false;
  if (hideWhen) {
    return JsxRuntime.jsx("div", {
                children: children,
                className: Cx.cx([
                      css.trigger,
                      className
                    ])
              });
  } else {
    return JsxRuntime.jsxs("div", {
                children: [
                  children,
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx("div", {
                              children: props.content,
                              className: css.tooltip
                            }),
                        className: Cx.cx([
                              withoutArrow ? css.containerWithoutArrow : css.container,
                              toClassName(props.position),
                              tooltipContainerClass
                            ])
                      })
                ],
                className: Cx.cx([
                      css.trigger,
                      className
                    ]),
                id: id !== undefined ? id : ""
              });
  }
}

var make = Tooltip;

export {
  css ,
  Position ,
  make ,
}
/* css Not a pure module */
