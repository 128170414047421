// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Checkbox from "../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as IconPlus from "../../../../../styleguide/icons/IconPlus.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconDelete from "../../../../../styleguide/icons/IconDelete.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactTelInput from "../../../../../bindings/ReactTelInput.res.js";
import * as TelephoneField from "../../../../../styleguide/forms/TelephoneField/TelephoneField.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardGeneralInformationScss from "./ProjectWizardGeneralInformation.scss";
import * as ProjectWizardGeneralInformation_ContactInputField from "./ProjectWizardGeneralInformation_ContactInputField.res.js";
import * as ProjectWizardGeneralInformation_FieldValidationMessage from "./ProjectWizardGeneralInformation_FieldValidationMessage.res.js";
import * as ProjectWizardGeneralInformation_Data_GeneralInformation from "./ProjectWizardGeneralInformation_Data_GeneralInformation.res.js";

var css = ProjectWizardGeneralInformationScss;

function ProjectWizardGeneralInformation_ContactInformationSection$ContactForm(props) {
  var contactValidationStatus = props.contactValidationStatus;
  var index = props.index;
  var updateContact = props.updateContact;
  var onDelete = props.onDelete;
  var contact = props.contact;
  return JsxRuntime.jsx("form", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(ProjectWizardGeneralInformation_ContactInputField.make, {
                                            label: "Full name",
                                            value: contact.fullName,
                                            onChange: (function (value) {
                                                updateContact("FullName", value);
                                              }),
                                            index: index,
                                            status: Belt_Option.map(contactValidationStatus, (function (status) {
                                                    return status.fullName;
                                                  }))
                                          }),
                                      JsxRuntime.jsx(ProjectWizardGeneralInformation_ContactInputField.make, {
                                            label: "Job title (optional)",
                                            value: Belt_Option.getWithDefault(contact.jobTitle, ""),
                                            onChange: (function (value) {
                                                updateContact("JobTitle", value);
                                              }),
                                            index: index,
                                            status: Belt_Option.map(contactValidationStatus, (function (status) {
                                                    return status.jobTitle;
                                                  }))
                                          }),
                                      JsxRuntime.jsx(ProjectWizardGeneralInformation_ContactInputField.make, {
                                            label: "Company name",
                                            value: contact.companyName,
                                            onChange: (function (value) {
                                                updateContact("CompanyName", value);
                                              }),
                                            noMarginRight: true,
                                            index: index,
                                            status: Belt_Option.map(contactValidationStatus, (function (status) {
                                                    return status.companyName;
                                                  }))
                                          })
                                    ],
                                    className: css.fieldsRow
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(ProjectWizardGeneralInformation_ContactInputField.make, {
                                            label: "Email",
                                            value: contact.email,
                                            onChange: (function (value) {
                                                updateContact("Email", value);
                                              }),
                                            index: index,
                                            status: Belt_Option.map(contactValidationStatus, (function (status) {
                                                    return status.email;
                                                  }))
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "project-general-info-contactPhone-" + String(index),
                                                    children: "Phone"
                                                  }),
                                              JsxRuntime.jsx(TelephoneField.make, {
                                                    id: "project-general-info-contactPhone-" + String(index),
                                                    value: contact.phone,
                                                    defaultCountry: "us",
                                                    status: Belt_Option.map(contactValidationStatus, (function (status) {
                                                            return ProjectWizardGeneralInformation_Data_GeneralInformation.Validate.getTelephoneFieldStatus(status.phone);
                                                          })),
                                                    onChange: (function (value, selectedCountry) {
                                                        var country = ReactTelInput.ReactTelInputData.countryFromJs(selectedCountry);
                                                        var phoneNumber = "+" + (country.dialCode + Js_string.replace("+" + country.dialCode, "", value).trim());
                                                        updateContact("Phone", phoneNumber);
                                                      }),
                                                    preferredCountries: ["us"],
                                                    wrapperClassName: css.telephoneFieldWrapper
                                                  }),
                                              JsxRuntime.jsx(ProjectWizardGeneralInformation_FieldValidationMessage.make, {
                                                    status: Belt_Option.map(contactValidationStatus, (function (status) {
                                                            return status.phone;
                                                          }))
                                                  })
                                            ],
                                            className: Cx.cx([
                                                  css.flexOne,
                                                  css.contactInputField,
                                                  css.inputWithMarginRight
                                                ])
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Checkbox.make, {
                                                    id: "project-general-info-contact-isPrimary" + String(index),
                                                    size: "LG",
                                                    checked: contact.isPrimary,
                                                    onChange: (function ($$event) {
                                                        var checked = $$event.target.checked;
                                                        updateContact("IsPrimary", checked ? "true" : "false");
                                                      })
                                                  }),
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "project-general-info-contact-isPrimary" + String(index),
                                                    children: "Set as primary"
                                                  }),
                                              JsxRuntime.jsx(Checkbox.make, {
                                                    id: "project-general-info-contact-isManager" + String(index),
                                                    size: "LG",
                                                    checked: contact.isManager,
                                                    className: css.managerCheckbox,
                                                    onChange: (function ($$event) {
                                                        var checked = $$event.target.checked;
                                                        updateContact("IsManager", checked ? "true" : "false");
                                                      })
                                                  }),
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "project-general-info-contact-isManager" + String(index),
                                                    children: "Is a manager"
                                                  })
                                            ],
                                            className: css.primaryCheckBoxContainer
                                          })
                                    ],
                                    className: css.flexContainer
                                  })
                            ],
                            className: Cx.cx([
                                  css.inputFieldBoxWrapper,
                                  css.containerWithMarginBottom
                                ])
                          }),
                      JsxRuntime.jsx("span", {
                            children: JsxRuntime.jsx(IconDelete.make, {
                                  size: "LG",
                                  color: "LightGray",
                                  className: css.fieldDeleteIcon,
                                  viewBoxSize: "20"
                                }),
                            onClick: (function (param) {
                                onDelete();
                              })
                          })
                    ],
                    className: css.contactFormContainer
                  })
            });
}

var ContactForm = {
  make: ProjectWizardGeneralInformation_ContactInformationSection$ContactForm
};

function ProjectWizardGeneralInformation_ContactInformationSection(props) {
  var validationStatus = props.validationStatus;
  var updateContact = props.updateContact;
  var removeContact = props.removeContact;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h2", {
                      children: "Enter Your Contact Information",
                      className: css.sectionSubHeading
                    }),
                JsxRuntime.jsx("p", {
                      children: "Add contacts to your quote so they can view proposals and pricing.",
                      className: css.sectionDescription
                    }),
                Belt_Array.mapWithIndex(props.contacts, (function (index, contact) {
                        return JsxRuntime.jsx(ProjectWizardGeneralInformation_ContactInformationSection$ContactForm, {
                                    contact: contact,
                                    onDelete: (function () {
                                        removeContact(index);
                                      }),
                                    updateContact: (function (field, value) {
                                        updateContact(index, field, value);
                                      }),
                                    index: index,
                                    contactValidationStatus: validationStatus !== undefined ? Belt_Array.get(validationStatus, index) : undefined
                                  }, "contact-" + String(index));
                      })),
                JsxRuntime.jsxs(Button.make, {
                      size: "SM",
                      color: "Teal",
                      className: css.addContactButton,
                      onClick: props.addContact,
                      children: [
                        JsxRuntime.jsx(IconPlus.make, {
                              size: "SM",
                              color: "White"
                            }),
                        "Add One More Contact Person"
                      ]
                    })
              ]
            });
}

var ReactTelInputData;

var make = ProjectWizardGeneralInformation_ContactInformationSection;

export {
  css ,
  ReactTelInputData ,
  ContactForm ,
  make ,
}
/* css Not a pure module */
