// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Project from "../../../../../models/Project.res.js";
import * as IconFile from "../../../../../styleguide/icons/IconFile.res.js";
import * as IconView from "../../../../../styleguide/icons/IconView.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconDelete from "../../../../../styleguide/icons/IconDelete.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardGeneralInformationScss from "./ProjectWizardGeneralInformation.scss";

var css = ProjectWizardGeneralInformationScss;

function ProjectWizardGeneralInformation_DocumentsWithRfpDetails$DocumentRow(props) {
  var onDelete = props.onDelete;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(IconFile.make, {
                              size: "LG",
                              color: "DarkGray"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: props.fileName,
                                      className: css.fileNameText
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: Project.$$Document.sizeText(props.byteSize),
                                      className: css.fileSizeText
                                    })
                              ],
                              className: css.fileInfoContainer
                            })
                      ],
                      className: css.flexContainerDoc
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(IconView.make, {
                              size: "LG",
                              color: "DarkGray",
                              className: css.iconView
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(IconDelete.make, {
                                    size: "LG",
                                    color: "DarkGray",
                                    viewBoxSize: "20"
                                  }),
                              onClick: (function (param) {
                                  onDelete();
                                })
                            })
                      ],
                      className: css.flexContainerDoc
                    })
              ],
              className: css.documentRow
            });
}

var DocumentRow = {
  make: ProjectWizardGeneralInformation_DocumentsWithRfpDetails$DocumentRow
};

function ProjectWizardGeneralInformation_DocumentsWithRfpDetails(props) {
  var removeDocument = props.removeDocument;
  var removeAlreadyUploadedDocument = props.removeAlreadyUploadedDocument;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h4", {
                      children: "Documents with RFP details (optional)",
                      className: css.documentsSectionHeading
                    }),
                Belt_Array.map(props.alreadyUploadedDocuments, (function ($$document) {
                        return JsxRuntime.jsx(ProjectWizardGeneralInformation_DocumentsWithRfpDetails$DocumentRow, {
                                    fileName: $$document.fileName,
                                    byteSize: $$document.byteSize,
                                    onDelete: (function () {
                                        removeAlreadyUploadedDocument($$document);
                                      })
                                  }, "already-uploaded-document-" + $$document.fileName);
                      })),
                Belt_Array.map(props.documents, (function ($$document) {
                        return JsxRuntime.jsx(ProjectWizardGeneralInformation_DocumentsWithRfpDetails$DocumentRow, {
                                    fileName: $$document.name,
                                    byteSize: $$document.size | 0,
                                    onDelete: (function () {
                                        removeDocument($$document);
                                      })
                                  }, "recently-uploaded-document-" + $$document.name);
                      }))
              ]
            });
}

var make = ProjectWizardGeneralInformation_DocumentsWithRfpDetails;

export {
  css ,
  DocumentRow ,
  make ,
}
/* css Not a pure module */
