// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as TextField from "../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserColocationRfpProjectWizardTooltip from "../UserColocationRfpProjectWizardTooltip/UserColocationRfpProjectWizardTooltip.res.js";
import * as ProjectWizardGeneralInformationScss from "./ProjectWizardGeneralInformation.scss";
import * as ProjectWizardGeneralInformation_FieldValidationMessage from "./ProjectWizardGeneralInformation_FieldValidationMessage.res.js";
import * as ProjectWizardGeneralInformation_Data_GeneralInformation from "./ProjectWizardGeneralInformation_Data_GeneralInformation.res.js";

var css = ProjectWizardGeneralInformationScss;

function ProjectWizardGeneralInformation_ContactInputField(props) {
  var tooltipName = props.tooltipName;
  var status = props.status;
  var __index = props.index;
  var __noMarginRight = props.noMarginRight;
  var __isFlexTwo = props.isFlexTwo;
  var onChange = props.onChange;
  var label = props.label;
  var isFlexTwo = __isFlexTwo !== undefined ? __isFlexTwo : false;
  var noMarginRight = __noMarginRight !== undefined ? __noMarginRight : false;
  var index = __index !== undefined ? __index : 0;
  var id = Js_string.replace(" ", "-", "project-general-info-" + (label + ("-" + String(index))));
  return JsxRuntime.jsxs("div", {
              children: [
                tooltipName !== undefined ? JsxRuntime.jsx(UserColocationRfpProjectWizardTooltip.make, {
                        field: tooltipName,
                        children: JsxRuntime.jsx(Label.make, {
                              forId: id,
                              status: status,
                              children: label
                            })
                      }) : JsxRuntime.jsx(Label.make, {
                        forId: id,
                        status: status,
                        children: label
                      }),
                JsxRuntime.jsx(TextField.make, {
                      id: id,
                      value: props.value,
                      status: Belt_Option.map(status, ProjectWizardGeneralInformation_Data_GeneralInformation.Validate.getTextFieldStatus),
                      onChange: (function ($$event) {
                          onChange($$event.target.value);
                        })
                    }),
                JsxRuntime.jsx(ProjectWizardGeneralInformation_FieldValidationMessage.make, {
                      status: status
                    })
              ],
              className: Cx.cx([
                    isFlexTwo ? css.flexTwo : css.flexOne,
                    css.contactInputField,
                    noMarginRight ? "" : css.inputWithMarginRight
                  ])
            });
}

var make = ProjectWizardGeneralInformation_ContactInputField;

export {
  css ,
  make ,
}
/* css Not a pure module */
