// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as Textarea from "../../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as TextField from "../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardGeneralInformationScss from "./ProjectWizardGeneralInformation.scss";
import * as ProjectWizardGeneralInformation_FieldValidationMessage from "./ProjectWizardGeneralInformation_FieldValidationMessage.res.js";
import * as ProjectWizardGeneralInformation_Data_GeneralInformation from "./ProjectWizardGeneralInformation_Data_GeneralInformation.res.js";

var css = ProjectWizardGeneralInformationScss;

function ProjectWizardGeneralInformation_DescribeProjectSection(props) {
  var titleValidationStatus = props.titleValidationStatus;
  var descriptionValidationStatus = props.descriptionValidationStatus;
  var updateDescription = props.updateDescription;
  var updateTitle = props.updateTitle;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h4", {
                      children: "Add Your Project Details, Documents, and Contacts",
                      className: css.sectionHeading
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "project-general-info-title",
                              children: "Title"
                            }),
                        JsxRuntime.jsx(TextField.make, {
                              id: "project-general-info-title",
                              value: props.title,
                              status: Belt_Option.map(titleValidationStatus, ProjectWizardGeneralInformation_Data_GeneralInformation.Validate.getTextFieldStatus),
                              onChange: (function ($$event) {
                                  updateTitle($$event.target.value);
                                })
                            }),
                        JsxRuntime.jsx(ProjectWizardGeneralInformation_FieldValidationMessage.make, {
                              status: titleValidationStatus
                            })
                      ],
                      className: css.inputRow
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "project-general-info-description",
                              children: "Description"
                            }),
                        JsxRuntime.jsx(Textarea.make, {
                              id: "project-general-info-description",
                              value: props.description,
                              rows: 6,
                              placeholder: "Ex. We're a growing business and need a private cage in Ashburn, VA to support our company's internal operations.",
                              status: Belt_Option.map(descriptionValidationStatus, ProjectWizardGeneralInformation_Data_GeneralInformation.Validate.getTextAreaStatus),
                              onChange: (function ($$event) {
                                  updateDescription($$event.target.value);
                                })
                            }),
                        JsxRuntime.jsx(ProjectWizardGeneralInformation_FieldValidationMessage.make, {
                              status: descriptionValidationStatus
                            })
                      ],
                      className: css.inputRow
                    })
              ]
            });
}

var make = ProjectWizardGeneralInformation_DescribeProjectSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
