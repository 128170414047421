// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as EmailValidator from "email-validator";

var ValidationResult = {};

var forbiddenEmails = [
  "gmail.co",
  "yahoo.co",
  "hotmail.co",
  "outlook.co",
  "aol.co"
];

function isValidText(text) {
  return text.length !== 0;
}

function isValidEmail(email) {
  return EmailValidator.validate(email);
}

function isValidBusinessEmail(email) {
  return Js_array.every((function (domain) {
                return !(function (__x) {
                            return Js_string.includes(domain, __x);
                          })(email);
              }), forbiddenEmails);
}

function getTextFieldStatus(x) {
  if (x.TAG === "Ok") {
    return "Valid";
  } else {
    return "Error";
  }
}

function getTextAreaStatus(x) {
  if (x.TAG === "Ok") {
    return "Valid";
  } else {
    return "Error";
  }
}

function getTelephoneFieldStatus(x) {
  if (x.TAG === "Ok") {
    return "Valid";
  } else {
    return "Error";
  }
}

function isValid(x) {
  if (x.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

function termsAndConditions(termsAndConditions$1, projectId, projectStatus) {
  if (termsAndConditions$1 || projectId !== undefined && projectStatus !== "Draft") {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else {
    return {
            TAG: "Error",
            _0: "Terms & Conditions are required"
          };
  }
}

function title(title$1) {
  var length = title$1.length;
  if (length > 0 && length <= 60) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else if (length === 0) {
    return {
            TAG: "Error",
            _0: "Title is required"
          };
  } else {
    return {
            TAG: "Error",
            _0: "Title must be less than 60 characters"
          };
  }
}

function description(description$1) {
  var length = description$1.length;
  if (length > 0 && length <= 2000) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else if (length === 0) {
    return {
            TAG: "Error",
            _0: "Description is required"
          };
  } else {
    return {
            TAG: "Error",
            _0: "Description must be less than 2000 characters"
          };
  }
}

function businessContactFields(businessName, businessContactName, businessEmail, businessPhone, headquartersAddress) {
  return {
          businessName: businessName.length !== 0 ? ({
                TAG: "Ok",
                _0: undefined
              }) : ({
                TAG: "Error",
                _0: "Business name is required"
              }),
          businessContactName: businessContactName.length !== 0 ? ({
                TAG: "Ok",
                _0: undefined
              }) : ({
                TAG: "Error",
                _0: "Business Contact Name is required"
              }),
          businessEmail: EmailValidator.validate(businessEmail) ? (
              isValidBusinessEmail(businessEmail) ? ({
                    TAG: "Ok",
                    _0: undefined
                  }) : ({
                    TAG: "Error",
                    _0: "Business email can not be personal"
                  })
            ) : ({
                TAG: "Error",
                _0: "Business email is not valid"
              }),
          headquartersAddress: headquartersAddress.length !== 0 ? ({
                TAG: "Ok",
                _0: undefined
              }) : ({
                TAG: "Error",
                _0: "Headquarters address is required"
              }),
          businessPhone: businessPhone.length > 7 ? ({
                TAG: "Ok",
                _0: undefined
              }) : ({
                TAG: "Error",
                _0: "Valid phone is required"
              })
        };
}

function contactFields(contact) {
  return {
          fullName: contact.fullName.length !== 0 ? ({
                TAG: "Ok",
                _0: undefined
              }) : ({
                TAG: "Error",
                _0: "Full name is required"
              }),
          jobTitle: {
            TAG: "Ok",
            _0: undefined
          },
          companyName: contact.companyName.length !== 0 ? ({
                TAG: "Ok",
                _0: undefined
              }) : ({
                TAG: "Error",
                _0: "Company name is required"
              }),
          email: EmailValidator.validate(contact.email) ? ({
                TAG: "Ok",
                _0: undefined
              }) : ({
                TAG: "Error",
                _0: "Valid email is required"
              }),
          phone: contact.phone.length > 7 ? ({
                TAG: "Ok",
                _0: undefined
              }) : ({
                TAG: "Error",
                _0: "Valid phone is required"
              })
        };
}

function isValidBusinessContact(result) {
  if (result.businessName.TAG === "Ok" && result.businessContactName.TAG === "Ok" && result.businessEmail.TAG === "Ok" && result.headquartersAddress.TAG === "Ok" && result.businessPhone.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

function isValidContact(result) {
  if (result.fullName.TAG === "Ok" && result.jobTitle.TAG === "Ok" && result.companyName.TAG === "Ok" && result.email.TAG === "Ok" && result.phone.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

function areContactInformationsValid(contacts) {
  var contactsValidationInfo = Belt_Array.map(contacts, isValidContact);
  return !Js_array.includes(false, contactsValidationInfo);
}

function areAllFieldsValid(x) {
  if (x === undefined) {
    return true;
  }
  var isTitleValid = isValid(x.title);
  var agreedTermsAndConditions = isValid(x.termsAndConditions);
  var isDescriptionValid = isValid(x.description);
  var areContactInformationsValid$1 = areContactInformationsValid(x.contacts);
  var areBusinessContactInformationsValid = isValidBusinessContact(x.businessContactFields);
  if (isTitleValid && isDescriptionValid && areContactInformationsValid$1 && areBusinessContactInformationsValid && agreedTermsAndConditions) {
    return true;
  } else {
    return false;
  }
}

var Validate = {
  forbiddenEmails: forbiddenEmails,
  isValidText: isValidText,
  isValidEmail: isValidEmail,
  isValidBusinessEmail: isValidBusinessEmail,
  getTextFieldStatus: getTextFieldStatus,
  getTextAreaStatus: getTextAreaStatus,
  getTelephoneFieldStatus: getTelephoneFieldStatus,
  isValid: isValid,
  termsAndConditions: termsAndConditions,
  title: title,
  description: description,
  businessContactFields: businessContactFields,
  contactFields: contactFields,
  isValidBusinessContact: isValidBusinessContact,
  isValidContact: isValidContact,
  areContactInformationsValid: areContactInformationsValid,
  areAllFieldsValid: areAllFieldsValid
};

export {
  ValidationResult ,
  Validate ,
}
/* email-validator Not a pure module */
