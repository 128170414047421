// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactTelInput from "../../../../../bindings/ReactTelInput.res.js";
import * as TelephoneField from "../../../../../styleguide/forms/TelephoneField/TelephoneField.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardGeneralInformationScss from "./ProjectWizardGeneralInformation.scss";
import * as ProjectWizardGeneralInformation_ContactInputField from "./ProjectWizardGeneralInformation_ContactInputField.res.js";
import * as ProjectWizardGeneralInformation_FieldValidationMessage from "./ProjectWizardGeneralInformation_FieldValidationMessage.res.js";
import * as ProjectWizardGeneralInformation_Data_GeneralInformation from "./ProjectWizardGeneralInformation_Data_GeneralInformation.res.js";

var css = ProjectWizardGeneralInformationScss;

function ProjectWizardGeneralInformation_BusinessContactInformationSection(props) {
  var validationStatus = props.validationStatus;
  var updateField = props.updateField;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h2", {
                      children: "Enter Your Business Information"
                    }),
                JsxRuntime.jsx("p", {
                      children: "In order to get quotes, providers require a valid legal business. Please enter in your information below.",
                      className: css.sectionDescription
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("form", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(ProjectWizardGeneralInformation_ContactInputField.make, {
                                            label: "Business Name",
                                            value: props.businessName,
                                            onChange: (function (value) {
                                                updateField(value, "BusinessName");
                                              }),
                                            status: Belt_Option.map(validationStatus, (function (status) {
                                                    return status.businessName;
                                                  }))
                                          }),
                                      JsxRuntime.jsx(ProjectWizardGeneralInformation_ContactInputField.make, {
                                            label: "Headquarters Address",
                                            value: props.headquartersAddress,
                                            onChange: (function (value) {
                                                updateField(value, "HeadquartersAddress");
                                              }),
                                            isFlexTwo: true,
                                            noMarginRight: true,
                                            status: Belt_Option.map(validationStatus, (function (status) {
                                                    return status.headquartersAddress;
                                                  }))
                                          })
                                    ],
                                    className: css.fieldsRow
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(ProjectWizardGeneralInformation_ContactInputField.make, {
                                            label: "Business Contact Name",
                                            value: props.businessContactName,
                                            onChange: (function (value) {
                                                updateField(value, "BusinessContactName");
                                              }),
                                            status: Belt_Option.map(validationStatus, (function (status) {
                                                    return status.businessContactName;
                                                  })),
                                            tooltipName: "Business Contact Name"
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "project-general-info-BusinessPhone",
                                                    children: "Business Phone"
                                                  }),
                                              JsxRuntime.jsx(TelephoneField.make, {
                                                    id: "project-general-info-BusinessPhone",
                                                    value: props.businessPhone,
                                                    defaultCountry: "us",
                                                    status: Belt_Option.map(validationStatus, (function (status) {
                                                            return ProjectWizardGeneralInformation_Data_GeneralInformation.Validate.getTelephoneFieldStatus(status.businessPhone);
                                                          })),
                                                    onChange: (function (value, selectedCountry) {
                                                        var country = ReactTelInput.ReactTelInputData.countryFromJs(selectedCountry);
                                                        var phoneNumber = "+" + (country.dialCode + Js_string.replace("+" + country.dialCode, "", value).trim());
                                                        updateField(phoneNumber, "BusinessPhone");
                                                      }),
                                                    preferredCountries: ["us"],
                                                    wrapperClassName: css.telephoneFieldWrapper
                                                  }),
                                              JsxRuntime.jsx(ProjectWizardGeneralInformation_FieldValidationMessage.make, {
                                                    status: Belt_Option.map(validationStatus, (function (status) {
                                                            return status.businessPhone;
                                                          }))
                                                  })
                                            ],
                                            className: Cx.cx([
                                                  css.flexOne,
                                                  css.contactInputField,
                                                  css.inputWithMarginRight
                                                ])
                                          }),
                                      JsxRuntime.jsx(ProjectWizardGeneralInformation_ContactInputField.make, {
                                            label: "Business Email",
                                            value: props.businessEmail,
                                            onChange: (function (value) {
                                                updateField(value, "BusinessEmail");
                                              }),
                                            noMarginRight: true,
                                            status: Belt_Option.map(validationStatus, (function (status) {
                                                    return status.businessEmail;
                                                  }))
                                          })
                                    ],
                                    className: css.flexContainer
                                  })
                            ]
                          }),
                      className: css.inputFieldBoxWrapper
                    })
              ],
              className: css.sectionContainer
            });
}

var ReactTelInputData;

var make = ProjectWizardGeneralInformation_BusinessContactInformationSection;

export {
  css ,
  ReactTelInputData ,
  make ,
}
/* css Not a pure module */
