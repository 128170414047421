// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Tooltip from "../../../../../styleguide/components/Tooltip/Tooltip.res.js";
import * as IconHelpTooltip from "../../../../../styleguide/icons/IconHelpTooltip.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserColocationRfpProjectWizardTooltipScss from "./UserColocationRfpProjectWizardTooltip.scss";

var css = UserColocationRfpProjectWizardTooltipScss;

function fromString(x) {
  switch (x) {
    case "Bandwidth" :
        return "Bandwidth";
    case "Burstable" :
        return "Burstable";
    case "Business Contact Name" :
        return "BusinessContactName";
    case "Cloud Direct Connects" :
        return "CloudDirectConnects";
    case "Cloud Direct Connects Bandwidth" :
        return "CloudDirectConnectsBandwidth";
    case "Cloud Direct Connects Burstable" :
        return "CloudDirectConnectsBurstable";
    case "Cloud Direct Connects Interconnect" :
        return "CloudDirectConnectsInterconnect";
    case "Cloud Direct Connects MPLS" :
        return "MPLS";
    case "Cloud Direct Connects Point to Point" :
        return "PointToPoint";
    case "Cloud Direct Connects SD-WAN" :
        return "SDWAN";
    case "Cloud Direct Connects VPN" :
        return "VPN";
    case "Cloud Platform" :
        return "CloudPlatform";
    case "Colocation space" :
        return "ColocationSpace";
    case "Crash Carts" :
        return "CrashCarts";
    case "Cross Connects" :
        return "CrossConnects";
    case "Cross Connects Interconnect" :
        return "CrossConnectsInterconnect";
    case "Cross Connects Preferred Provider" :
        return "CrossConnectsPreferredProvider";
    case "Deep Racks (>42\")" :
        return "DeepRacks";
    case "FEDRAMP Compliant" :
        return "FEDRAMPCompliant";
    case "FISMA Compliant" :
        return "FISMACompliant";
    case "HIPAA Compliant" :
        return "HIPAACompliant";
    case "Hourly Remote Hands" :
        return "RemoteHands";
    case "IPv4 Addresses" :
        return "IPv4Address";
    case "IPv6 Addresses" :
        return "IPv6Address";
    case "Interconnect" :
        return "Interconnect";
    case "Load Ramping" :
        return "LoadRamping";
    case "Managed Firewall" :
        return "ManagedFirewall";
    case "Managed Services" :
        return "ManagedServices";
    case "NIST Compliant" :
        return "NISTCompliant";
    case "Onsite Parking" :
        return "OnsiteParking";
    case "PCI DSS 3.2 Validation" :
        return "PCIDSS";
    case "PDU Provided" :
        return "PduProvided";
    case "Power circuit" :
        return "PowerCircuit";
    case "Preferred Provider" :
        return "PreferredProvider";
    case "Redundant Circuits" :
        return "RedundantCircuits";
    case "Roof Rights" :
        return "RoofRights";
    case "SOC 3 Compliant" :
        return "SOC3Compliant";
    case "SSAE 16 SOC 1 Type 1 Compliant" :
    case "SSAE 16 SOC 1 Type 2 Compliant" :
        return "SSAE16";
    case "SSAE 18 SOC 1 Type 1 Compliant" :
        return "SOC1Type1";
    case "SSAE 18 SOC 1 Type 2 Compliant" :
        return "SOC1Type2";
    case "Secure Storage Area" :
        return "SecureStorage";
    case "Shelving Units" :
        return "ShelvingUnits";
    case "Staging Area" :
        return "StagingArea";
    case "Submetered Power" :
        return "SubmeteredPower";
    case "Total Power" :
        return "TotalPower";
    case "Wide Racks (>24\")" :
        return "WideRacks";
    default:
      return "Default";
  }
}

function tooltip(x) {
  if (x === "PCIDSS") {
    return "PCI Data Security Standard is for safe handling customer account data such as credit cards.";
  } else if (x === "ShelvingUnits") {
    return "Metal shelves that attach to racks for tower servers or hardware not rack mountable.";
  } else if (x === "PowerCircuit") {
    return "Denoted by Amps (A) and Volts (V). If unsure, skip ahead to the total power field.";
  } else if (x === "TotalPower") {
    return "Total power utilization of your IT equipment. Kilowatts (kW), kilovolt-amp (kVA) or megawatts (MW).";
  } else if (x === "PduProvided") {
    return "Power strip used to plug in servers, routers, switches and other IT hardware.";
  } else if (x === "RedundantCircuits") {
    return "Primary and secondary power feed for additional redundancy capabilities.";
  } else if (x === "ManagedFirewall") {
    return "Protects IT equipment and applications from vulnerabilities and attacks.";
  } else if (x === "CloudDirectConnectsBurstable") {
    return "On-demand bandwidth required when network traffic hits the limits of the dedicated bandwidth.";
  } else if (x === "ManagedServices") {
    return "Technical support for monitoring and management of the IT equipment, network, applications and OS.";
  } else if (x === "NISTCompliant") {
    return "National Institute of Standards and Technology outlines nine steps toward compliance with FISMA.";
  } else if (x === "SecureStorage") {
    return "Designated area where customers ship their IT equipment to for safe keeping.";
  } else if (x === "CrossConnectsPreferredProvider") {
    return "Telecom carriers, ISPs and network provider preferences for connecting to the internet or an existing network.";
  } else if (x === "StagingArea") {
    return "Designated area to organize and build IT hardware for deployment in facility.";
  } else if (x === "OnsiteParking") {
    return "Parking within the confines of the data center or within close proximity.";
  } else if (x === "SubmeteredPower") {
    return "Consumption data on customer power usage within a multi-tenant data center for billing purposes.";
  } else if (x === "SOC3Compliant") {
    return "Auditor's opinion of SOC 2 components.  Less detailed and technical than a SOC 2 report.";
  } else if (x === "PreferredProvider") {
    return "Telecom carriers, ISPs and network provider preferences for connecting to the internet or an existing network.";
  } else if (x === "CloudPlatform") {
    return "A platform or service provided by a cloud provider. AWS, Azure, Google Cloud, etc.";
  } else if (x === "Default") {
    return "";
  } else if (x === "BusinessContactName") {
    return "The name of the person who is the main contact at the company for this project.";
  } else if (x === "RoofRights") {
    return "Roof access for the installation of antennas and/or masts.";
  } else if (x === "CloudDirectConnectsBandwidth") {
    return "Maximum amount of data that can be transmitted over an Internet connection. Measured in Megabits per second (Mbps) or Gigabits per second (Gbps). ";
  } else if (x === "SDWAN") {
    return "Virtual WAN architecture that uses MPLS and broadband to securely connect users to networks and applications.";
  } else if (x === "FISMACompliant") {
    return "Federal Information Security Management Act defines a comprehensive framework to protect government information, operations and assets against natural or man-made threats.";
  } else if (x === "VPN") {
    return "Encrypted private connection that reduces network costs, increases bandwidth throughput, and provides additional layers of security.";
  } else if (x === "CrashCarts") {
    return "Console furniture with a monitor, keyboards and cables for diagnosis and troubleshooting.";
  } else if (x === "Interconnect") {
    return "Connect IT equipment to the internet or network through physical links such as fiber, coax, copper, etc.";
  } else if (x === "IPv6Address") {
    return "Assigned to each device connected to a network that uses the IP for communication.";
  } else if (x === "LoadRamping") {
    return "Customers that anticipate that their power utilization will ramp up or down over time.";
  } else if (x === "CloudDirectConnects") {
    return "Secure private connection between customer IT infrastructure and a cloud provider.";
  } else if (x === "Burstable") {
    return "On-demand bandwidth required when network traffic hits the limits of the dedicated bandwidth.";
  } else if (x === "CrossConnectsInterconnect" || x === "CloudDirectConnectsInterconnect") {
    return "Connect IT equipment to the internet or network through physical links such as fiber, coax, copper, etc.";
  } else if (x === "IPv4Address") {
    return "Assigned to each device connected to a network that uses the IP for communication.";
  } else if (x === "CrossConnects") {
    return "Dedicated connectivity to telecom carriers, ISPs and network providers within the data center facility.";
  } else if (x === "SSAE16") {
    return "Audit measuring the controls relevant to financial reporting. Provides guidance for auditors assessing financial statement controls at service organizations.";
  } else if (x === "ColocationSpace") {
    return "Enter quantity (optional) and select colocation space. Select \"Unsure\" if unsure as to how much space is required. Skip to the total power field.";
  } else if (x === "FEDRAMPCompliant") {
    return "Standardizes security requirements for use of cloud services for government agencies.";
  } else if (x === "HIPAACompliant") {
    return "Health Insurance Portability and Accountability for health information (PHI), or patient health data (medical records).";
  } else if (x === "RemoteHands") {
    return "Service for delegation of IT management and maintenance tasks to onsite facility technicians.";
  } else if (x === "SOC1Type1") {
    return "Description and assertion of controls, as reported by the company.";
  } else if (x === "SOC1Type2") {
    return "Auditors test of the accuracy of the controls over a specified period of time.";
  } else if (x === "Bandwidth") {
    return "Maximum amount of data that can be transmitted over an Internet connection. Measured in Megabits per second (Mbps) or Gigabits per second (Gbps).";
  } else if (x === "MPLS") {
    return "Routing technology and WAN architecture for VPNs, traffic engineering and QoS.";
  } else if (x === "PointToPoint") {
    return "High-performance, secure connections between two sites with Class of Service (CoS).";
  } else if (x === "WideRacks") {
    return "Width greater than 24\" and are used for special IT infrastructure such as servers.";
  } else {
    return "Depth greater than 42\" and are used for special IT infrastructure such as servers.";
  }
}

function tooltipClass(x) {
  if (x === "RemoteHands" || x === "SecureStorage" || x === "PCIDSS") {
    return css.iconFineRightTooltip;
  } else if (x === "ShelvingUnits") {
    return "";
  } else if (x === "PointToPoint" || x === "Bandwidth" || x === "CloudDirectConnectsInterconnect" || x === "CrossConnectsInterconnect" || x === "Interconnect" || x === "SDWAN" || x === "CloudDirectConnectsBandwidth" || x === "BusinessContactName" || x === "TotalPower" || x === "PowerCircuit") {
    return css.iconCenterLeftTooltip;
  } else if (x === "PduProvided") {
    return "";
  } else if (x === "FEDRAMPCompliant" || x === "SubmeteredPower" || x === "ManagedServices" || x === "RedundantCircuits") {
    return css.iconCenterRightTooltip;
  } else if (x === "ManagedFirewall" || x === "CloudDirectConnectsBurstable" || x === "NISTCompliant" || x === "CrossConnectsPreferredProvider" || x === "StagingArea" || x === "OnsiteParking" || x === "SOC3Compliant" || x === "PreferredProvider" || x === "CloudPlatform" || x === "Default" || x === "RoofRights" || x === "FISMACompliant") {
    return "";
  } else if (x === "MPLS" || x === "VPN") {
    return css.iconLeftTooltip;
  } else if (x === "CrashCarts" || x === "IPv6Address" || x === "LoadRamping" || !(x === "SOC1Type2" || x === "SOC1Type1" || x === "SSAE16" || x === "CrossConnects" || x === "CloudDirectConnects")) {
    return "";
  } else {
    return css.iconRightTooltip;
  }
}

var Field = {
  fromString: fromString,
  tooltip: tooltip,
  tooltipClass: tooltipClass
};

function UserColocationRfpProjectWizardTooltip(props) {
  var children = props.children;
  var __iconContainerClass = props.iconContainerClass;
  var iconContainerClass = __iconContainerClass !== undefined ? __iconContainerClass : "";
  var fieldEntity = fromString(props.field);
  if (fieldEntity !== "Default") {
    return JsxRuntime.jsxs("div", {
                children: [
                  children,
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Tooltip.make, {
                              content: tooltip(fieldEntity),
                              position: "Top",
                              tooltipContainerClass: tooltipClass(fieldEntity),
                              withoutArrow: true,
                              children: JsxRuntime.jsx(IconHelpTooltip.make, {
                                    size: "SM"
                                  })
                            }),
                        className: Cx.cx([
                              css.icon,
                              css.tooltipPositionContainer,
                              iconContainerClass
                            ])
                      })
                ],
                className: css.info
              });
  } else {
    return children;
  }
}

var make = UserColocationRfpProjectWizardTooltip;

export {
  css ,
  Field ,
  make ,
}
/* css Not a pure module */
