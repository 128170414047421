// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconHelpTooltip(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Help";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsxs("g", {
                    children: [
                      JsxRuntime.jsx("path", {
                            d: "M8 16A8 8 0 108 0a8 8 0 000 16z",
                            fill: "#C4F0EE"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M5.5 6.2c0-.3.1-.7.3-1 .2-.4.5-.6.9-.9.4-.2.8-.3 1.4-.3.4 0 .9 0 1.2.3.4.2.7.4.9.8a2 2 0 01.1 1.8 30.6 30.6 0 01-1.7 2V9l-.1.4c0 .4-.3.5-.6.5l-.5-.1-.1-.6.1-.7c0-.3.2-.4.3-.6l.6-.6.5-.4.2-.4.1-.4a1 1 0 00-.3-.7 1 1 0 00-.7-.3c-.4 0-.7.1-.8.3-.2.2-.4.5-.5.9-.1.4-.3.6-.6.6l-.5-.2a.7.7 0 01-.2-.5zM7.9 12c-.2 0-.3 0-.5-.2a.8.8 0 01-.2-.6c0-.2 0-.4.2-.6l.5-.2.6.2.2.6c0 .3 0 .4-.2.6l-.6.2z",
                            fill: "#099891"
                          })
                    ],
                    fill: "none",
                    fillRule: "nonzero"
                  })
            });
}

var make = IconHelpTooltip;

export {
  make ,
}
/* Icon Not a pure module */
