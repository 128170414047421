// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ErrorMessage from "../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardGeneralInformationScss from "./ProjectWizardGeneralInformation.scss";

var css = ProjectWizardGeneralInformationScss;

function ProjectWizardGeneralInformation_FieldValidationMessage(props) {
  var status = props.status;
  if (status !== undefined && status.TAG !== "Ok") {
    return JsxRuntime.jsx(ErrorMessage.make, {
                className: css.error,
                children: status._0
              });
  } else {
    return null;
  }
}

var make = ProjectWizardGeneralInformation_FieldValidationMessage;

export {
  css ,
  make ,
}
/* css Not a pure module */
